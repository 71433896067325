@import "./sass/abstracts/mixins";

.app {
  @include respond(min-desktop) {
    padding: 0 20rem 5rem;
  }

  @include respond(max-big-phone) {
    padding: 0 5rem 3rem 8rem;
  }

  padding: 0 8rem 3rem;

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1 {
    font-size: 3.2rem;
    font-weight: 600;
    padding: 12rem 0 0;
    margin: 0;
    color: #005b8b;
  }

  h2 {
    font-size: 3rem;
    font-weight: 550;
    line-height: 165%;
    margin-top: 0;
  }

  p {
    line-height: 180%;
  }

  &__intro {
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 50;
    padding: 2.5rem 0;
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  }
}

.btn__cta {
  width: fit-content;
  background-color: #005b8b;
  color: #fff;
  padding: 1.2rem 1.8rem;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 2rem;
}
