@import "./sass/abstracts/mixins";

html {
  font-size: 62.5%;

  @include respond(max-tablet-portrait) {
    font-size: 58.5%;
  }

  @include respond(max-big-phone) {
    font-size: 54.5%;
  }

  @include respond(max-small-phone) {
    font-size: 50%;
  }

  @include respond(min-desktop) {
    font-size: 68.75%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, "Titillium Web", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
