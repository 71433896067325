@import "../../sass/abstracts/mixins";

.menu {
  @include respond(min-big-phone) {
    display: none;
  }

  position: absolute;
  top: 3.4rem;
  left: 0;

  @include respond(max-big-phone) {
    left: 2.2rem;
  }

  &__icon {
    position: fixed;
    color: #a2a2a2;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: 10px 10px 50px -6px rgba(0, 0, 0, 0.1);
    font-size: 2.8rem;
    z-index: 200;

    &--item {
      padding-left: 3rem;
      margin-top: 5rem;

      &:last-child {
        margin-bottom: 5rem;
      }
    }
  }
}
