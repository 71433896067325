@import "../../../sass/abstracts/mixins";

.skills {
  @include respond(max-big-phone) {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 3rem;
  grid-template-areas: ". ." ". ." ". .";
  height: auto;

  &__section {
    &:last-child {
      @include respond(max-big-phone) {
        margin-top: 2rem;
      }
    }

    &--title {
      font-size: 2.6rem;
      font-weight: 600;
    }

    &--list {
      font-size: 2rem;

      li {
        margin-bottom: 2rem;
        height: 100%/3;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          margin-top: 0.8rem;
          font-size: 2rem;
          color: #a2a2a2;
          font-weight: 400;
          font-style: italic;
          line-height: initial;
        }
      }
    }
  }
}
