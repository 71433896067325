@import "../../sass/abstracts/mixins";

.navbar {
  @include respond(min-desktop) {
    margin: 0 20rem;
  }

  @include respond(max-big-phone) {
    justify-content: center;
    // padding-top: 2rem;
  }

  display: flex;
  justify-content: space-between;
  margin: 0 8rem;
  // margin-bottom: 3rem;
  // padding-top: 5rem;

  &__home {
    font-size: 3.6rem;
    color: #005b8b !important;
  }

  &__menu {
    @include respond(max-big-phone) {
      display: none;
    }

    font-size: 2rem;
    display: flex;
    align-items: center;

    li {
      margin-right: 2rem;
      font-weight: 600;
      transition: all 0.2s;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #005b8b;
      }
    }

    &--active {
      color: #005b8b;
    }
  }
}
