@import "../../sass/abstracts/mixins";

.landing {
  @include respond(max-big-phone) {
    justify-content: center;
  }

  padding-top: 15rem;
  display: flex;
  flex-direction: column;

  &__text {
    @include respond(max-tablet-portrait) {
      width: 80%;
    }

    @include respond(max-big-phone) {
      font-size: 3.8rem;
      width: 100%;
    }

    @include respond(max-small-phone) {
      font-size: 3.6rem;
      width: 100%;
    }

    width: 50%;
    margin-bottom: 0;
  }

  &__details {
    @include respond(max-tablet-portrait) {
      width: 80%;
    }

    @include respond(max-big-phone) {
      width: 100%;
    }

    font-size: 2.4rem;
    width: 50%;
    margin-top: 1rem;

    .line-break {
      margin-bottom: 2rem;
    }
  }
}
