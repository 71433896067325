@mixin respond($breakpoint) {
  @if $breakpoint == max-tablet-portrait {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == max-big-phone {
    @media (max-width: 425px) {
      @content;
    }
  }

  @if $breakpoint == max-small-phone {
    @media (max-width: 320px) {
      @content;
    }
  }

  // kills burger menu icon
  @if $breakpoint == min-big-phone {
    @media (min-width: 426px) {
      @content;
    }
  }

  // adjusts project cards width
  @if $breakpoint == min-tablet-portrait {
    @media (min-width: 769px) {
      @content;
    }
  }

  @if $breakpoint == min-desktop {
    @media (min-width: 1440px) {
      @content;
    }
  }
}
