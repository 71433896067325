@import "../../../sass/abstracts/mixins";

.books {
  @include respond(max-big-phone) {
    display: flex;
    flex-direction: column;
    // margin-top: 0;
    margin-top: 4rem;
  }

  &__section {
    &--title {
      font-size: 2.6rem;
      font-weight: 600;
      margin-top: 0;
    }

    &--list {
      font-size: 2rem;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        svg {
          margin-right: 1rem;
        }

        &:last-child {
          margin-bottom: 0;
        }

        // #book-name {
        //   text-decoration: underline;
        // }

        #book-author {
          font-size: 2rem;
          color: #a2a2a2;
          font-weight: 400;
          font-style: italic;
          text-decoration: none;
        }
      }
    }
  }
}
