@import "../../sass/abstracts/mixins";

.about {
  // margin-bottom: 5rem;

  &__details {
    @include respond(max-big-phone) {
      flex-direction: column;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    img {
      @include respond(min-desktop) {
        width: 20%;
      }

      @include respond(max-big-phone) {
        width: 50%;
        margin: 2rem 0;
      }

      border-radius: 50%;
      // box-shadow: 10px 10px 50px -6px rgba(0, 0, 0, 0.15);
      box-shadow: 12px 10px 30px -25px rgba(0, 0, 0, 0.3);
      width: 25%;
      height: auto;
    }

    p {
      @include respond(max-big-phone) {
        width: 100%;
      }

      font-size: 2rem;
      width: 70%;

      a {
        text-decoration: underline;
      }
    }
  }
}
