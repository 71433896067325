.footer {
  margin: 0 auto;
  text-align: center;

  a {
    margin-right: 2rem;
    color: inherit;

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 3rem;
      height: 3rem;
      transition: all 0.2s;

      &:hover {
        color: #a2a2a2;
      }
    }
  }
}
